import React from "react"
import Layout from "../components/reusables/Layout"
import SEO from "../components/reusables/seo"

export default function Thanks() {
  return (
    <>
      <SEO title="Thanks" />
      <Layout>
        <h1 className="mt-24 text-4xl text-primary text-center">
          Thanks For Contacting Us
        </h1>
      </Layout>
    </>
  )
}
